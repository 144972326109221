/**
 *  @ These are all the app specific constants we will be using:
 */
export const SESSION_STATE = 'SESSION_STATE';
export const SESSION_ID = 'SESSION_ID';
export const USER_DATA = 'USER_DATA';
export const BACKGROUND_EFFECT = 'BACKGROUND_EFFECT';

export const USER_ROLES = [
  {
    label: 'Administrator',
    short: 'Admin',
    value: 1,
  },
  {
    label: 'Beta User',
    short: 'Beta',
    value: 2,
  },
  {
    label: 'General User',
    short: 'General',
    value: 3,
  },
];

export const DATA_TABLE_HEADER = [
  {
    Header: 'Date',
    accessor: 'date',
    class: 'date-th',
  },
  {
    Header: 'Headline',
    accessor: 'headline',
    class: 'headline-th',
  },
  {
    Header: 'URL',
    accessor: 'url',
    class: 'url-th',
  },
  {
    Header: 'Opening Text',
    accessor: 'opentext',
    class: 'opentext-th',
  },
  {
    Header: 'Hit Sentence',
    accessor: 'hit',
    class: 'hit-th',
  },
];

export const GROUP_TABLE_HEADER = [
  {
    Header: 'Group name',
    accessor: 'name',
    class: 'group-th',
  },
  {
    Header: 'Searches/Topics',
    accessor: 'searches',
    class: 'searches-th',
  },
  {
    Header: 'Users',
    accessor: 'users',
    class: 'users-th',
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    class: 'actions-th',
  },
];

export const USER_TABLE_HEADER = [
  {
    Header: 'First Name',
    accessor: 'firstname',
    class: 'firstname-th',
  },
  {
    Header: 'Last Name',
    accessor: 'lastname',
    class: 'lastname-th',
  },
  {
    Header: 'Email',
    accessor: 'email',
    class: 'email-th',
  },
  {
    Header: 'Status',
    accessor: 'status',
    class: 'status-th',
  },
  {
    Header: 'Searches/Topics',
    accessor: 'searches',
    class: 'searches-th',
  },
  {
    Header: 'Electoral district',
    accessor: 'district',
    class: 'district-th',
  },
  {
    Header: 'MarketPulse',
    accessor: 'marketpulse',
    class: 'marketpulse-th',
  },
];

export const DISTRICT_TABLE_HEADER = [
  {
    Header: 'District',
    accessor: 'name',
    class: 'district-th',
  },
  {
    Header: 'Users',
    accessor: 'users',
    class: 'users-th',
  },
];

export const GOOGLE_TRENDS_TABLE_HEADER = [
  {
    Header: 'Name',
    accessor: 'name',
    class: 'name-th',
  },
  {
    Header: 'Users',
    accessor: 'users',
    class: 'users-th',
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    class: 'actions-th',
  },
];
