import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import DistrictAdd from '../DistrictAdd/DistrictAdd';
import WarningPopup from '../Popup/Warning';

import { deleteDistrict } from '../../../http/districtAPI';
import { setNotify } from '../../../datastore/actions/actionActions';

import CityIcon from '../../../assets/images/icons/city.svg';

const DistrictProfile = ({ data, onSaveHandler, onDeleteHandler }) => {
  const dispatch = useDispatch();

  const config = useSelector((state) => state.config.config);

  const [districtEditing, setDistrictEditing] = useState(false);
  const [deleteRequest, setDeleteRequest] = useState(false);

  const toggleDistrictEdit = () => {
    setDistrictEditing((prev) => !prev);
  };

  const toggleDistrictDelete = () => {
    setDeleteRequest((prev) => !prev);
  };

  const handleSave = () => {
    toggleDistrictEdit();
    onSaveHandler(data.id);
  };

  const handleDistrictDelete = async () => {
    const res = await deleteDistrict(config, data.id);

    if (res.status === 200) {
      dispatch(
        setNotify({
          status: true,
          type: 'success',
          title: 'Success',
          message: res.data.message,
        }),
      );
    } else {
      dispatch(
        setNotify({
          status: true,
          type: 'error',
          title: 'Error',
          message: res.data.message,
        }),
      );
    }
    onDeleteHandler();
  };

  return (
    <>
      <div className="user-profile">
        <div className="user-profile-edits">
          <div className="edit-btn" onClick={toggleDistrictEdit}>
            Edit
          </div>
          <div className="edit-btn warning" onClick={toggleDistrictDelete}>
            Delete
          </div>
        </div>
        <div className="user-profile-left">
          <div className="user-profile-icon">
            <img src={CityIcon} />
          </div>
        </div>
        <div className="user-profile-right">
          <div className="user-profile-name">{data.district}</div>
          <div className="user-profile-data">
            <div className="user-info-item">
              <div className="uii-top">Representative</div>
              <div className="uii-bottom">{data.representative}</div>
            </div>
            <div className="user-info-item">
              <div className="uii-top">Province</div>
              <div className="uii-bottom">{data.province}</div>
            </div>
          </div>
        </div>
      </div>
      {districtEditing && (
        <DistrictAdd
          isOpen={districtEditing}
          onSaveHandler={handleSave}
          onCloseHandler={toggleDistrictEdit}
          districtData={data}
        />
      )}
      {deleteRequest && (
        <WarningPopup
          isOpen={deleteRequest}
          title="Deleting Electoral District"
          onSubmitHandler={handleDistrictDelete}
          onCancelHandler={toggleDistrictDelete}
        />
      )}
    </>
  );
};

export default DistrictProfile;
