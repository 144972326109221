import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';

import InputItem from '../Forms/InputItem/InputItem';
import FormButton from '../Forms/FormButton/FormButton';
import ElectionsTable from '../ElectionsTable/ElectionsTable';
import MapUploader from '../Uploader/MapUploader/MapUploader';
import Fader from '../Loaders/Fader';

import { setNotify } from '../../../datastore/actions/actionActions';
import { createDistrict, saveDistrict } from '../../../http/districtAPI';
import useOutsideClick from '../../../util/useOutsideClick';

import CityIcon from '../../../assets/images/icons/city.svg';
import UsernameIcon from '../../../assets/images/icons/user.svg';
import StateIcon from '../../../assets/images/icons/state.svg';
import './DistrictAdd.scss';

const DistrictAdd = ({ isOpen, onSaveHandler, onCloseHandler, districtData }) => {
  const dispatch = useDispatch();

  const [districtName, setDistrictName] = useState('');
  const [representative, setRepresentative] = useState('');
  const [province, setProvince] = useState('');
  const [electionDetails, setElectionDetails] = useState([
    {
      election: '',
      winner: '',
      prevWinner: '',
      candidates: [{ party: '', candidate: '', votes: '', percent: '', percentChange: '', expenditures: '' }],
      validVotes: { total: '', percent: '', percentChange: '' },
      rejectedVotes: { total: '', percent: '', percentChange: '' },
      turnout: { total: '', percent: '', percentChange: '' },
      expenseLimit: '',
      eligibleVoters: '',
      swing: '',
      isOpen: true,
    },
  ]);
  const [atlasMap, setAtlasMap] = useState({
    dbName: '',
    dbKey: '',
    file: null,
    isNew: false,
  });
  const [detailedMap, setDetailedMap] = useState({
    dbName: '',
    dbKey: '',
    file: null,
    isNew: false,
  });
  const [saving, setSaving] = useState(false);

  const config = useSelector((state) => state.config.config);

  const windowRef = useRef();

  const formadVotesData = ({ total, percent, percentChange, isChangePositive }) => ({
    total,
    percent: percent ?? '',
    percentChange: percentChange ? `${isChangePositive ? '+' : '-'}${percentChange}` : '',
  });

  const parseElectionData = (election) => {
    const candidates = election.candidates.map(
      ({ id, party, candidate, votes, percent, percentChange, isChangePositive, expenditures }) => ({
        id,
        party,
        candidate,
        votes,
        percent: percent ?? '',
        percentChange: percentChange ? `${isChangePositive ? '+' : '-'}${percentChange}` : '',
        expenditures: expenditures ?? '',
      }),
    );
    setElectionDetails((prevDetails) => [
      ...prevDetails,
      {
        id: election.id,
        election: election.election,
        winner: election.winner,
        prevWinner: election.prevWinner,
        candidates,
        validVotes: formadVotesData(election.validVotes),
        rejectedVotes: formadVotesData(election.rejectedVotes),
        turnout: formadVotesData(election.turnout),
        expenseLimit: election.expenseLimit ?? '',
        eligibleVoters: election.eligibleVoters,
        swing: `${election.swing.isChangePositive ? '+' : '-'}${election.swing.percentChange}`,
        isOpen: false,
      },
    ]);
  };

  const clearForm = () => {
    setDistrictName('');
    setRepresentative('');
    setProvince('');
    setElectionDetails([]);
    setAtlasMap({
      dbName: '',
      dbKey: '',
      file: null,
      isNew: false,
    });
    setDetailedMap({
      dbName: '',
      dbKey: '',
      file: null,
      isNew: false,
    });
  };

  useEffect(() => {
    if (districtData) {
      clearForm();
      setDistrictName(districtData.district);
      setRepresentative(districtData.representative);
      setProvince(districtData.province);
      districtData.elections.forEach((election) => parseElectionData(election));

      if (districtData.atlasMapName) {
        setAtlasMap((map) => ({
          ...map,
          dbName: districtData.atlasMapName,
          dbKey: districtData.atlasMapPdfKey,
        }));
      }

      if (districtData.detailedMapName) {
        setDetailedMap((map) => ({
          ...map,
          dbName: districtData.detailedMapName,
          dbKey: districtData.detailedMapPdfKey,
        }));
      }
    }
  }, [districtData]);

  const handleAddElection = () => {
    setElectionDetails((prevDetails) => [
      ...prevDetails,
      {
        election: '',
        winner: '',
        prevWinner: '',
        candidates: [{ party: '', candidate: '', votes: '', percent: '', percentChange: '', expenditures: '' }],
        validVotes: { total: '', percent: '', percentChange: '' },
        rejectedVotes: { total: '', percent: '', percentChange: '' },
        turnout: { total: '', percent: '', percentChange: '' },
        expenseLimit: '',
        eligibleVoters: '',
        swing: '',
        isOpen: true,
      },
    ]);
  };

  const checkIsCandidateValid = (candidate) => {
    return candidate.party && candidate.candidate && candidate.votes && candidate.percent;
  };

  const checkIsElectionValid = (election) => {
    return (
      election.election &&
      election.winner &&
      election.prevWinner &&
      election.candidates.length > 0 &&
      election.candidates.every(checkIsCandidateValid) &&
      election.validVotes.total &&
      election.validVotes.percent &&
      election.rejectedVotes.total &&
      election.turnout.total &&
      election.turnout.percent &&
      election.eligibleVoters &&
      election.swing
    );
  };

  const checkIsValid = () => {
    if (districtName && representative && province && electionDetails.every(checkIsElectionValid)) {
      return true;
    }

    return false;
  };

  const handleSave = async () => {
    if (checkIsValid()) {
      setSaving(true);

      const res = await saveDistrict(
        config,
        districtData.id,
        districtName,
        representative,
        province,
        electionDetails.map(({ isOpen, ...rest }) => rest),
        atlasMap,
        detailedMap,
      );

      if (res.status === 200) {
        dispatch(
          setNotify({
            status: true,
            type: 'success',
            title: 'Success',
            message: res.data.message,
          }),
        );
        onSaveHandler();
      } else {
        dispatch(
          setNotify({
            status: true,
            type: 'error',
            title: 'Error',
            message: res.message,
          }),
        );
      }
      setSaving(false);
    } else {
      dispatch(
        setNotify({
          status: true,
          type: 'error',
          title: 'Error',
          message: 'Fill in all required fields',
        }),
      );
    }
  };

  const handleCreate = async () => {
    if (checkIsValid()) {
      setSaving(true);

      const res = await createDistrict(
        config,
        districtName,
        representative,
        province,
        electionDetails.map(({ isOpen, ...rest }) => rest),
        atlasMap.file,
        detailedMap.file,
      );

      if (res.status === 201) {
        dispatch(
          setNotify({
            status: true,
            type: 'success',
            title: 'Success',
            message: res.data.message,
          }),
        );
        onSaveHandler();
      } else {
        dispatch(
          setNotify({
            status: true,
            type: 'error',
            title: 'Error',
            message: res.message,
          }),
        );
      }
      setSaving(false);
    } else {
      dispatch(
        setNotify({
          status: true,
          type: 'error',
          title: 'Error',
          message: 'Fill in all required fields',
        }),
      );
    }
  };

  useOutsideClick(windowRef, null, isOpen, onCloseHandler);

  return (
    <div className="login-wrap user-add-wrap">
      <div className="login-view">
        <div ref={windowRef} className="login-box add-user-box">
          {!saving ? (
            <div className="logo-large">
              {districtData ? 'Edit Electoral District' : 'Create A New Electoral District'}
            </div>
          ) : (
            <>
              <div className="logo-large">
                {districtData ? 'Saving Electoral District Information' : 'Creating Electoral District'}
              </div>
              <div className="logo-sub user-space">
                The process should take no longer than 30 seconds. Please Wait...
              </div>
            </>
          )}
          {!saving ? (
            <>
              <div className="add-form">
                <PerfectScrollbar
                  options={{
                    wheelPropagation: false,
                    autoHide: false,
                  }}
                >
                  <div className="add-fields">
                    <InputItem
                      label="District Name"
                      type="text"
                      icon={CityIcon}
                      onChange={(e) => setDistrictName(e.target.value)}
                      value={districtName}
                    />
                    <InputItem
                      label="Representative"
                      type="text"
                      icon={UsernameIcon}
                      onChange={(e) => setRepresentative(e.target.value)}
                      value={representative}
                    />
                    <InputItem
                      label="Province"
                      type="text"
                      icon={StateIcon}
                      onChange={(e) => setProvince(e.target.value)}
                      value={province}
                    />
                  </div>
                  {electionDetails.map((election, i) => (
                    <ElectionsTable
                      key={i}
                      index={i}
                      open={!election.id}
                      electionDetails={election}
                      setElectionDetails={setElectionDetails}
                    />
                  ))}
                  <span className="add-election" onClick={handleAddElection}>
                    Add Election
                  </span>
                  <MapUploader
                    id="1"
                    label="Atlas Map"
                    title="Choose Atlas Map To Upload (.pdf format)"
                    selectedFile={atlasMap}
                    setSelectedFile={setAtlasMap}
                  />
                  <MapUploader
                    id="2"
                    label="Overview Detailed Wall Map"
                    title="Choose Overview Detailed Wall Map To Upload (.pdf format)"
                    selectedFile={detailedMap}
                    setSelectedFile={setDetailedMap}
                  />
                </PerfectScrollbar>
              </div>
              <div className="submit">
                <FormButton
                  subLink={'Cancel'}
                  subLinkClick={onCloseHandler}
                  text={districtData ? 'Save' : 'Create'}
                  onClick={districtData ? handleSave : handleCreate}
                />
              </div>
            </>
          ) : (
            <Fader size={50} />
          )}
        </div>
      </div>
    </div>
  );
};

export default DistrictAdd;
