import React, { useEffect } from 'react';
import { useTable, usePagination, useFilters, useGlobalFilter, useSortBy } from 'react-table';

import InputItem from '../Forms/InputItem/InputItem';
import CustomSelect from '../Forms/CustomSelect/CustomSelect';

import searchIcon from '../../../assets/images/icons/search.svg';
import './Table.scss';

const Table = ({ data, columns, clickHandler, clickHandlerTriggers = [], searchLabel = 'Search' }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    setGlobalFilter,
  } = useTable({ columns, data }, useFilters, useGlobalFilter, useSortBy, usePagination);

  const { globalFilter, pageIndex, pageSize } = state;

  const handleRowClicked = (id) => {
    if (id) {
      clickHandler(id);
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [pageIndex, pageSize]);

  return (
    <>
      <InputItem
        label={searchLabel}
        type="text"
        icon={searchIcon}
        onChange={(e) => setGlobalFilter(e.target.value || undefined)}
        value={globalFilter || ''}
      />
      <table {...getTableProps()} className="global-table-component">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  if (clickHandlerTriggers.includes(cell.column.class)) {
                    return (
                      <td {...cell.getCellProps()} onClick={() => handleRowClicked(row.original.id)}>
                        {cell.render('Cell')}
                      </td>
                    );
                  }
                  return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="pagination">
        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>{' '}
        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>{' '}
        <button onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>{' '}
        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>{' '}
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </span>
        <CustomSelect
          options={[5, 10, 20].map((pageSize) => ({ label: `Show ${pageSize}`, value: pageSize }))}
          selectedOption={pageSize}
          setSelectedOption={(value) => setPageSize(Number(value))}
          defaultOption={`Show ${pageSize}`}
          isPagination={true}
        />
      </div>
    </>
  );

  /* return (
    <div className={header ? 'table-row header' : id ? 'table-row clickable' : 'table-row'} onClick={handleRowClicked}>
      {
        data.map((row) => {
          return <TableCell text={row.text} classes={row.class} id={row.id}  />
        })
      }
    </div>
  ) */
};

export default Table;
