import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Table from '../../components/Table/Table';
import GoogleTrendAdd from '../../components/GoogleTrendAdd/GoogleTrendAdd';
import TagList from '../TagList/TagList';
import WarningPopup from '../../components/Popup/Warning';
import Fader from '../../components/Loaders/Fader';

import { getAllUsers } from '../../../http/usersAPI';
import { getAllTrends, deleteTrend, assignTrend } from '../../../http/trendsAPI';
import { GOOGLE_TRENDS_TABLE_HEADER } from '../../../util/constants/AppConstants';
import { setNotify } from '../../../datastore/actions/actionActions';

const GoogleTrends = () => {
  const dispatch = useDispatch();

  const config = useSelector((state) => state.config.config);
  const backgroundEffectState = useSelector((state) => state.settings.backgroundEffect);

  const [backgroundEffectChecked, setBackgroundEffectChecked] = useState(backgroundEffectState);

  const [dataPreviewLoading, setDataPreviewLoading] = useState(true);
  const [trendsData, setTrendsData] = useState([]);

  const [activeUsersList, setActiveUsersList] = useState([]);
  const [activeTrendId, setActiveTrendId] = useState(null);
  const [taggingType, setTaggingType] = useState('');

  const [isAddTrend, setIsAddTrend] = useState(false);

  const [usersData, setUsersData] = useState([]);

  const [isTaggingActive, setIsTaggingActive] = useState(false);

  const [deleteRequest, setDeleteRequest] = useState(false);

  const openDeleteWindow = (trendId) => {
    setActiveTrendId(trendId);
    setDeleteRequest(true);
  };

  const addItem = (id, list, type, setActiveList) => {
    setTaggingType(type);
    setIsTaggingActive(true);
    setActiveList(list);
    setActiveTrendId(id);
  };

  const handleRenderList = (list, id, type) => {
    return list.map((item) => {
      if (item === '+') {
        return (
          <span className="search-label add" onClick={() => addItem(id, list, type, setActiveUsersList)}>
            +
          </span>
        );
      }
      return <span className="search-label">{item.name}</span>;
    });
  };

  const handleRenderActions = (trendId) => {
    return (
      <div className="user-profile-edits">
        <div className="edit-btn warning" onClick={() => openDeleteWindow(trendId)}>
          Delete
        </div>
      </div>
    );
  };

  const handleSetTrendsData = (trendsData) => {
    const trendsArr = trendsData.map((item) => ({
      id: item.id,
      name: item.name,
      users: handleRenderList([...item.users, '+'], item.id, 'users'),
      actions: handleRenderActions(item.id),
    }));
    setTrendsData(trendsArr);
  };

  const handleGetTrendsData = async () => {
    const res = await getAllTrends(config);

    if (res.status === 200) {
      setDataPreviewLoading(false);
      handleSetTrendsData(res.data.trends);
    } else {
      console.log('ERROR: Grabbing Google Trends');
    }
  };

  const trendsTableColumns = useMemo(() => GOOGLE_TRENDS_TABLE_HEADER, []);

  const handleTrendCreate = () => {
    setIsAddTrend(false);
    handleGetTrendsData();
  };

  const handleGetUsersData = async () => {
    const res = await getAllUsers(config);

    if (res.status === 200) {
      setUsersData(res.data.users);
    } else {
      console.log('ERROR: Grabbing Users');
    }
  };

  const handleTrendDelete = async () => {
    const res = await deleteTrend(config, activeTrendId);

    if (res.status === 200) {
      dispatch(
        setNotify({
          status: true,
          type: 'success',
          title: 'Success',
          message: res.data.message,
        }),
      );
      setActiveTrendId(null);
      handleGetTrendsData();
      setDeleteRequest(false);
    } else {
      dispatch(
        setNotify({
          status: true,
          type: 'error',
          title: 'Error',
          message: res.data.message,
        }),
      );
    }
  };

  const handleTagsCancel = () => {
    setIsTaggingActive(false);
    setActiveTrendId(null);
  };

  useEffect(() => {
    if (dataPreviewLoading && config.api) {
      handleGetTrendsData();
    }
  }, [dataPreviewLoading, config]);

  useEffect(() => {
    setBackgroundEffectChecked(backgroundEffectState);
  }, [backgroundEffectState]);

  useEffect(() => {
    if (config.api && dataPreviewLoading) {
      handleGetUsersData();
    }
  }, [config]);

  return (
    <div className="dashboard-components">
      <div className="dc-left full">
        <div className="sentiment-trend chart-component">
          <div className="user-manager">
            <div className="edit-btn" onClick={() => setIsAddTrend(true)}>
              Add New
            </div>
          </div>

          {!dataPreviewLoading ? (
            <Table columns={trendsTableColumns} data={trendsData} searchLabel="Search for Google Trend Topics" />
          ) : (
            <div className="sentiment-charts">
              <Fader />
            </div>
          )}
        </div>

        {isAddTrend && (
          <GoogleTrendAdd
            isOpen={isAddTrend}
            onSaveHandler={handleTrendCreate}
            onCloseHandler={() => setIsAddTrend(false)}
          />
        )}
        {isTaggingActive && (
          <TagList
            isOpen={isTaggingActive}
            taggingType={taggingType}
            taggingList={usersData}
            startTaggingList={activeUsersList}
            handleTagsCancel={handleTagsCancel}
            activeItemId={activeTrendId}
            handleGetItemsData={handleGetTrendsData}
            setIsTaggingActive={setIsTaggingActive}
            request={assignTrend}
          />
        )}
        {deleteRequest && (
          <WarningPopup
            isOpen={deleteRequest}
            onSubmitHandler={handleTrendDelete}
            onCancelHandler={() => setDeleteRequest(false)}
            title="Deleting Google Trend Topic"
          />
        )}
      </div>
    </div>
  );
};

export default GoogleTrends;
