import { apiRequestDev } from './apiRequest';

export const getAllTrends = async (config) => apiRequestDev(config, '/trends/all');

export const createTrend = async (config, name) => {
  return apiRequestDev(config, '/trends/create', 'post', { name });
};

export const deleteTrend = async (config, id) => apiRequestDev(config, `/trends/${id}/delete`, 'delete');

export const assignTrend = async (config, activeTrendId, activeUsersList) => {
  return apiRequestDev(config, '/trends/users', 'post', {
    trend_id: activeTrendId,
    users: activeUsersList,
  });
};
