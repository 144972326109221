import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import InputItem from '../Forms/InputItem/InputItem';
import FormButton from '../Forms/FormButton/FormButton';
import Fader from '../Loaders/Fader';

import { setNotify } from '../../../datastore/actions/actionActions';
import { createTrend } from '../../../http/trendsAPI';
import useOutsideClick from '../../../util/useOutsideClick';

const GoogleTrendAdd = ({ isOpen, onSaveHandler, onCloseHandler, loading }) => {
  const dispatch = useDispatch();

  const [name, setName] = useState('');
  const [saving, setSaving] = useState(false);

  const config = useSelector((state) => state.config.config);

  const windowRef = useRef();

  const checkIsValid = () => {
    if (name) {
      return true;
    }

    return false;
  };

  const handleCreate = async () => {
    if (checkIsValid()) {
      setSaving(true);

      const res = await createTrend(config, name.trim());

      if (res.status === 201) {
        dispatch(
          setNotify({
            status: true,
            type: 'success',
            title: 'Success',
            message: res.data.message,
          }),
        );
        onSaveHandler();
      } else {
        dispatch(
          setNotify({
            status: true,
            type: 'error',
            title: 'Error',
            message: res.message,
          }),
        );
      }
      setSaving(false);
    } else {
      dispatch(
        setNotify({
          status: true,
          type: 'error',
          title: 'Error',
          message: 'Fill in all the fields',
        }),
      );
    }
  };

  useOutsideClick(windowRef, null, isOpen, onCloseHandler);

  return (
    <div className="login-wrap user-add-wrap">
      <div className="login-view">
        {!loading ? (
          <div ref={windowRef} className="login-box add-user-box">
            {!saving ? (
              <div className="logo-large">Create A New Google Trend Topic</div>
            ) : (
              <>
                <div className="logo-large">Creating Google Trend Topic</div>
                <div className="logo-sub user-space">
                  The process should take no longer than 30 seconds. Please Wait...
                </div>
              </>
            )}
            {!saving ? (
              <>
                <div className="add-user-left">
                  <InputItem
                    label="Topic name"
                    type={'text'}
                    icon={null}
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                  />
                </div>
                <div className="submit">
                  <FormButton subLink={'Cancel'} subLinkClick={onCloseHandler} text="Create" onClick={handleCreate} />
                </div>
              </>
            ) : (
              <Fader size={50} />
            )}
          </div>
        ) : (
          <div ref={windowRef} className="login-box add-user-box">
            <Fader />
          </div>
        )}
      </div>
    </div>
  );
};

export default GoogleTrendAdd;
