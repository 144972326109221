import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import LeftBar from '../app/components/LeftBar/LeftBar';
import Searches from '../app/views/Searches/Searches';
import Groups from '../app/views/Groups/Groups';
import Users from '../app/views/Users/Users';
import Districts from '../app/views/Districts/Districts';
import GoogleTrends from '../app/views/GoogleTrends/GoogleTrends';

const AppRoutes = ({ sessionState }) => {
  const action = useSelector((state) => state.action);
  const [loggingIn, setLoggingIn] = useState(false);
  const [loginAttempt, setLoginAttempt] = useState(false);

  useEffect(() => {
    if (action.loggingIn) {
      setLoginAttempt(true);
      setLoggingIn(true);
      return;
    }
    setLoginAttempt(false);
    setLoggingIn(false);
  }, [action]);

  return (
    <div className={loggingIn ? 'blur-frame' : !loginAttempt && !sessionState ? 'hidden' : ''}>
      <Router>
        <Routes>
          <Route exact path="/" element={<LeftBar />}>
            <Route index element={<Searches />} />
            <Route path="search_groups" element={<Groups />} />
            <Route path="users" element={<Users />} />
            <Route path="electoral_districts" element={<Districts />} />
            <Route path="google_trends" element={<GoogleTrends />} />
          </Route>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </div>
  );
};

export default AppRoutes;
